const url = {
  createResponsable: "assignee",
  createInstruction: "instruction",
  createEmployee: "employee",
  createCustomer: "customer",
  createCustomerEducation: "customer/education",
  createCustomerEmployer: "customer/job",
  createRole: "role",
  verifyCustomer: ({ cpf }) => `public/customer/verify?cpf=${cpf}`,
  roleList: ({ is_select = 0 }) => `role?is_select=${is_select}`,
  updateRole: ({ id }) => "role/" + id,
  viewRole: ({ id }) => "role/" + id,
  removeRole: ({ id }) => "role/" + id,
  viewPermissions: () => "permissions",
  removeResponsable: ({ id }) => "assignee/" + id,
  responsableList: ({ page }) => "assignee?page=" + page,
  fullResponsableList: () => "assignee",
  viewResponsable: ({ id }) => "assignee/" + id,
  viewInstructions: ({ id }) => "instruction/" + id,
  updateResponsable: ({ id }) => "assignee/" + id,
  viewEmployee: ({ id }) => "employee/" + id,
  viewCustomer: ({ id }) => "customer/" + id,
  updateEmployee: ({ id }) => "employee/" + id,
  updateInstruction: ({ id }) => "instruction/" + id,
  updateCustomer: ({ id }) => "customer/" + id,
  upcomingBirthdays: () => "customer/upcoming-birthdays",
  updateCustomerEmployer: ({ id }) => "customer/job/" + id,
  removeCustomerEmployer: ({ id }) => "customer/job/" + id,
  updateCustomerEducation: ({ id }) => "customer/education/" + id,
  employeeList: ({ page = 1, name = "" }) =>
    `employee?page=${page}&name=${name}`,
  removeEmployee: ({ id }) => "employee/" + id,
  removeInstruction: ({ id }) => "instruction/" + id,
  removeCustomer: ({ id }) => "customer/" + id,
  removeCustomerEducation: ({ id }) => "customer/education/" + id,
  customerListEducation: ({ page, id }) =>
    `${"customer/" + id + "/educations?page=" + page}`,
  customerListEmployer: ({ page, id }) =>
    `${"customer/" + id + "/jobs?page=" + page}`,
  // login actions
  login: "auth/login",
  logout: "auth/logout",
  changePassword: "auth/change-password",
  recoverPassword: "auth/forgot-password",
  confirmationToken: "auth/confirmation-token",
  refresh: "auth/refresh",
  profile: () => "me",
  //service
  createService: "service",
  serviceList: ({ page }) => "service?page=" + page,
  fullServiceList: () => "service",
  fullServicePublicList: ({ is_commercialized = 1 }) =>
    `public/service?is_commercialized=${is_commercialized}`,
  removeService: ({ id }) => "service/" + id,
  updateService: ({ id }) => "service/" + id,
  viewService: ({ id }) => "service/" + id,
  //client
  clientList: ({ page = 1, name = "", institution_name = "", coren = "" }) =>
    `customer?page=${page}&name=${name}&institution_name=${institution_name}&coren_state=${coren}`,
  fullClientList: ({ name = "", institution_name = "", coren = "" }) =>
    `customer?name=${name}&institution_name=${institution_name}&coren_state=${coren}`,
  viewPortal: ({ id }) => "customer/portal/" + id,
  viewEducation: ({ id }) => "customer/education/" + id,
  viewEmployer: ({ id }) => "customer/job/" + id,
  customerListPortals: ({ page, id }) =>
    `${"customer/" + id + "/portals?page=" + page}`,
  updateCustomerPortal: ({ id }) => "customer/portal/" + id,
  createCustomerPortal: "customer/portal",
  removeCustomerPortal: ({ id }) => "customer/portal/" + id,
  //flow
  createFlow: "flow",
  flowsList: ({
    page = null,
    customers = null,
    assignees = null,
    status = null,
    out_of_time = null,
  }) =>
    `customer/flows?page=${page}${customers ? `&customers=${customers}` : ""}${
      assignees ? `&assignees=${assignees}` : ""
    }${status ? `&status=${status}` : ""}${
      out_of_time ? `&out_of_time=${out_of_time}` : ""
    }`,
  flowsListUser: ({ page, id }) => `customer/${id}/flows?page=${page}`,
  flow: ({ id }) => `flow/${id}`,
  removeInstructionFromFlow: ({ id }) => "flow/instruction/" + id,
  //dashboard
  dashboardTotal: () => "dashboard/customers",
  dashboardFlows: ({ page }) => "dashboard/flows?page=" + page,
  dashboardEmployeeInteractions: ({ page }) =>
    "dashboard/employee/interactions?page=" + page,
  dashboardInteractions: ({ page }) => "dashboard/interactions?page=" + page,
  // prospect
  publicProspect: "public/prospect",
  prospectClient: "prospect",
  updateProspectClient: ({ id }) => "prospect/" + id,
  prospect: ({ id }) => `prospect/${id}`,
  prospectList: ({
    name = "",
    service = "",
    status = "",
    assignee = "",
    follow_up = "",
    hide_closed = true,
    page,
  }) =>
    `prospect?name=${name}&service=${service}&status=${status}&assignee=${assignee}&follow_up_pending=${follow_up}&hide_closed=${hide_closed}&page=${page}`,
  removeProspect: ({ id }) => "prospect/" + id,
  prospectLog: ({ id }) => "prospect/" + id + "/activity",
  prospectActivity: ({ prospectId, activityId }) =>
    `prospect/${prospectId}/activity/${activityId}`,
  // interaction
  interactionList: ({ page }) => "flow/interaction?page=" + page,
  postInteractionMessage: "flow/interaction",
  flowInteractions: ({ page, flowId, pageSize }) =>
    `flow/${flowId}/interactions?page=${page}&pageSize=${pageSize}`,
  resendInteraction: ({ id }) => `flow/interaction/${id}/resend`,
  
  interaction: ({ id }) => `flow/interaction/${id}`,
  updateMessage: ({ id }) => `flow/interaction/messages/${id}`,
  toggleInteraction: ({ id }) => `flow/interaction/${id}/togglepin`,
  markAsRead: ({ id }) => `flow/interaction/${id}/mark-as-read`,
  unreadInteractionByAssigneeId: ({ assignee_id }) => `flow/interaction/unread_interaction/${assignee_id}`,
  // instructions
  flowUpdateInstruction: "flow/instruction",
  instructionsList: ({ page }) => "instruction?page=" + page,
  fullInstructionsList: () => "instruction",
  instructions: ({ id }) => "instruction?id=" + id,
  step: ({ id }) => "step/" + id,

  updateStep: ({ id }) => "step/" + id,
  
  getAttachments: ({ id }) => `flow/${id}/attachments`,

  deleteAttachment: ({ id }) => `attachment/${id}`,

  //list of attachments by user by Carlos
  getAttachmentsByUser: ({ id }) => `attachment/user/${id}`,

  //documents
  documents: ({ page }) => "me/documents?page=" + page,
  //status flow
  statusFlow: () => "flow-step-status-type",
  removeFlow: ({ customerId, serviceId }) =>
    `customer/${customerId}/service/${serviceId}`,


  //add AvantageClubCategory
  createAdvantageClubCategory: "advantage-club-category",
  updateAdvantageClubCategory: ({ id }) => "advantage-club-category/" + id,
  removeAdvantageClubCategory: ({ id }) => "advantage-club-category/" + id,
  viewAdvantageClubCategory: ({ id }) => "advantage-club-category/" + id,
  advantageClubCategoryList: ({ page }) =>
    "advantage-club-category?page=" + page,

  //add AvantageClub
  createAdvantageClub: "advantage-club",
  updateAdvantageClub: ({ id }) => "advantage-club/" + id,
  removeAdvantageClub: ({ id }) => "advantage-club/" + id,
  viewAdvantageClub: ({ id }) => "advantage-club/" + id,
  advantageClubList: ({ page }) => "advantage-club?page=" + page,

  updateAdvantageClubImage: `advantage-club/update-image`,

  // Route::get('categories/{id}', [AdvantageClubController::class, 'getAdvantageClubsByCategories']);
  // Route::get('categories/{id}/is-active', [AdvantageClubController::class, 'getAdvantageClubsByCategoriesAndIsActive']);

  getAdvantageClubsByCategories: ({ id }) =>
    `advantage-club/categories/${id}`,

  getAdvantageClubsByCategoriesAndIsActive: ({ id }) =>
    `advantage-club/categories/${id}/is-active`,


    getImage: ({ id }) => `images/clubs/${id}`,

};
export default url;
