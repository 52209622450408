import { Col, Row } from "antd";

import React from "react";
import bg from "../../assets/images/bg-bna.png";
import styles from "./styled.module.scss";

export default function LeftContent() {
  return (
    <Col lg={12} span={24} className="hp-p-0 hp-bg-color-black-20 hp-bg-color-dark-90 hp-position-relative">
      <Row className="hp-image-row hp-h-100 hp-pb-md-32">
        <Col className="hp-logo-item hp-m-sm-16 hp-m-md-32 hp-m-64 hp-text-item hp-text-center">
          <h4 className={`hp-text-color-black-100 hp-text-color-dark-0 hp-mx-lg-16 hp-mb-16 ${styles["hp-title-smaller"]}`}>
            Tenha um controle de todo o seu processo através do BNA System.
          </h4>
          <h5 className={`h4 hp-mb-0 hp-font-weight-400 hp-text-color-black-80 hp-text-color-dark-30 ${styles["hp-text-smaller"]}`}>
            Nosso sistema é a ferramenta perfeita para ajudar a simplificar o seu processo.
          </h5>
        </Col>
        <Col span={24}>
          <Row align="middle" justify="center" className="hp-h-100">
            <Col span={24} className="hp-bg-item hp-text-center hp-mb-md-32">
              <img src={bg} alt="Background Image" className={`${styles["hp-w-100"]} hp-image`} />
            </Col>
            <Col xl={18} span={24} className="hp-text-item hp-text-center">
              {/* <h4 className="hp-text-color-black-100 hp-text-color-dark-0 hp-mx-lg-16 hp-mb-16">Tenha um controle de todo o seu processo através do BNA System.</h4>
              <p className="h4 hp-mb-0 hp-font-weight-400 hp-text-color-black-80 hp-text-color-dark-30">
                Nosso sistema é a ferramenta perfeita para ajudar a simplificar o seu processo.
              </p> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
