import {
  BookSaved,
  Chart,
  DocumentText,
  Gift,
  Home3,
  Personalcard,
  Profile2User,
  ProgrammingArrows,
  TagUser,
  User,
} from "iconsax-react";

import { getRoutesPermissions } from "../../router/permissions";

const employeeMenu = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Home3 size={18} />,
    allowedPermissions: getRoutesPermissions("dashboard"),
    navLink: "/dashboard",
  },
  {
    id: "responsable",
    title: "Responsável",
    icon: <Profile2User size={18} />,
    children: [
      {
        id: "responsable-list",
        title: "Ver responsáveis",
        navLink: "/responsable-list",
        allowedPermissions: getRoutesPermissions("responsable-list"),
      },
      {
        id: "create-responsable",
        title: "Criar responsável",
        navLink: "/create-responsable",
        allowedPermissions: getRoutesPermissions("create-responsable"),
      },
    ],
  },
  {
    id: "employee",
    title: "Funcionário",
    icon: <User size={18} />,
    children: [
      {
        id: "employee-list",
        title: "Ver funcionários",
        navLink: "/employee-list",
        allowedPermissions: getRoutesPermissions("employee-list"),
      },
      {
        id: "create-employee",
        title: "Criar funcionário",
        navLink: "/create-employee",
        allowedPermissions: getRoutesPermissions("create-employee"),
      },
    ],
  },
  {
    id: "role",
    title: "Perfil",
    icon: <TagUser size={18} />,
    children: [
      {
        id: "role-list",
        title: "Ver perfis",
        navLink: "/role-list",
        allowedPermissions: getRoutesPermissions("role-list"),
      },
      {
        id: "create-role",
        title: "Criar perfil",
        navLink: "/create-role",
        allowedPermissions: getRoutesPermissions("create-role"),
      },
    ],
  },
  {
    id: "client",
    title: "Cliente",
    icon: <User size={18} variant="Bold" />,
    children: [
      {
        id: "list-client",
        title: "Ver clientes",
        navLink: "/client-list",
        allowedPermissions: getRoutesPermissions("client-list"),
      },
      {
        id: "create-client",
        title: "Criar cliente",
        navLink: "/create-client",
        allowedPermissions: getRoutesPermissions("create-client"),
      },
    ],
  },
  {
    id: "Serviços",
    title: "Serviços",
    icon: <DocumentText size={18} />,
    children: [
      {
        id: "service",
        title: "Ver Serviços",
        navLink: "/service-list",
        allowedPermissions: getRoutesPermissions("service-list"),
      },
      {
        id: "create-service",
        title: "Criar serviço",
        navLink: "/create-service",
        allowedPermissions: getRoutesPermissions("create-service"),
      },
    ],
  },
  {
    id: "instructions",
    title: "Instruções",
    icon: <BookSaved size={18} />,
    children: [
      {
        id: "list-instructions",
        title: "Ver instruções",
        navLink: "/instructions-list",
        allowedPermissions: getRoutesPermissions("instructions-list"),
      },
      {
        id: "create-instruction",
        title: "Criar instrução",
        navLink: "/create-instruction",
        allowedPermissions: getRoutesPermissions("create-instruction"),
      },
    ],
  },
  {
    id: "flow",
    title: "Fluxo",
    icon: <ProgrammingArrows size={18} />,
    children: [
      {
        id: "list-flow",
        title: "Ver fluxos",
        navLink: "/flow-list",
        allowedPermissions: getRoutesPermissions("flow-list"),
      },
      {
        id: "create-flow",
        title: "Criar fluxo",
        navLink: "/create-flow",
        allowedPermissions: getRoutesPermissions("create-flow"),
      },
    ],
  },
  {
    id: "prospect-client",
    title: "Prospecção",
    icon: <Personalcard size={18} />,
    children: [
      {
        id: "prospect-list",
        title: "Ver prospecções",
        navLink: "/prospect-list",
        allowedPermissions: getRoutesPermissions("prospect-list"),
      },
      {
        id: "create-prospect",
        title: "Criar prospecção",
        navLink: "/prospect-client",
        allowedPermissions: getRoutesPermissions("prospect-client"),
      },
    ],
  },
  {
    id: "advantage-club",
    title: "Clube de vantagens",
    icon: <Gift size={18} />,
    children: [
      {
        id: "advantage-club-category-list",
        title: "Ver categorias",
        navLink: "/advantage-club-category-list",
        allowedPermissions: getRoutesPermissions(
          "advantage-club-category-list"
        ),
      },
      {
        id: "create-club-vantagens-category",
        title: "Criar categoria",
        navLink: "/create-club-vantagens-category",
        allowedPermissions: getRoutesPermissions("advantage-club-catergory"),
      },
      {
        id: "advantage-club-list",
        title: "Ver vantagens",
        navLink: "/advantage-club-list",
        allowedPermissions: getRoutesPermissions("advantage-club-list"),
      },
      {
        id: "create-advantage-club",
        title: "Criar vantagens",
        navLink: "/create-advantage-club",
        allowedPermissions: getRoutesPermissions("create-advantage-club"),
      },
    ],
  },
  {
    id: "general-flow-report",
    title: "Relatórios",
    icon: <Chart size={18} />,
    children: [
      {
        id: "general-flow-report",
        title: "Fluxo Geral",
        navLink: "/general-flow-report",
        allowedPermissions: getRoutesPermissions("general-flow-report"),
      },
      {
        id: "unread-interactions-report",
        title: "Interações não lidas",
        navLink: "/unread-interactions-report",
        allowedPermissions: getRoutesPermissions("unread-interactions-report"),
      },
    ],
  },
];

const clientMenu = [
  {
    id: "list-flow",
    title: "Meus serviços",
    navLink: "/flow-list",
    icon: <DocumentText size={18} />,
  },
  {
    id: "profile",
    title: "Ficha Cadastral",
    navLink: "/profile",
    icon: <User size={18} />,
  },
  //add advantage club view
  {
    id: "advantage-club",
    title: "Clube de vantagens",
    icon: <Gift size={18} />,
    navLink: "/advantage-club-list",
  },
];

export { employeeMenu, clientMenu };
